
  * {
    margin: 0px;
    box-sizing: border-box;
    padding: 0px;
}

  html {
    font-family: var(--font-poppins), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px;
}

  @media (min-width: 640px) {

    html {
        font-size: 16px;
    }
}

  body {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

  h1 {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
}

  h2,
  h3 {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
}

  h4,
  h5,
  h6 {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
}

  input[type='search']::-webkit-search-cancel-button {
    display: none;
}

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
.container {
    width: 100%;
}
@media (min-width: 640px) {

    .container {
        max-width: 640px;
    }
}
@media (min-width: 768px) {

    .container {
        max-width: 768px;
    }
}
@media (min-width: 1024px) {

    .container {
        max-width: 1024px;
    }
}
@media (min-width: 1280px) {

    .container {
        max-width: 1280px;
    }
}
@media (min-width: 1536px) {

    .container {
        max-width: 1536px;
    }
}
.pointer-events-none {
    pointer-events: none;
}
.visible {
    visibility: visible;
}
.invisible {
    visibility: hidden;
}
.static {
    position: static;
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.-left-\[15px\] {
    left: -15px;
}
.-right-4 {
    right: -1rem;
}
.-top-3 {
    top: -0.75rem;
}
.bottom-0 {
    bottom: 0px;
}
.bottom-28 {
    bottom: 7rem;
}
.bottom-main {
    bottom: 1.5rem;
}
.left-0 {
    left: 0px;
}
.left-1 {
    left: 0.25rem;
}
.left-1\.5 {
    left: 0.375rem;
}
.left-1\/2 {
    left: 50%;
}
.left-4 {
    left: 1rem;
}
.left-\[300px\] {
    left: 300px;
}
.left-\[50\%\] {
    left: 50%;
}
.left-sm {
    left: 0.5rem;
}
.right-0 {
    right: 0px;
}
.right-1\/2 {
    right: 50%;
}
.right-8 {
    right: 2rem;
}
.right-\[-4px\] {
    right: -4px;
}
.right-sm {
    right: 0.5rem;
}
.top-0 {
    top: 0px;
}
.top-1\/2 {
    top: 50%;
}
.top-2\/4 {
    top: 50%;
}
.top-2xl {
    top: 3rem;
}
.top-6 {
    top: 1.5rem;
}
.top-8 {
    top: 2rem;
}
.top-\[-4px\] {
    top: -4px;
}
.top-\[150px\] {
    top: 150px;
}
.top-\[4px\] {
    top: 4px;
}
.top-sm {
    top: 0.5rem;
}
.z-0 {
    z-index: 0;
}
.z-10 {
    z-index: 10;
}
.z-40 {
    z-index: 40;
}
.z-appbar {
    z-index: 95;
}
.z-filters {
    z-index: 90;
}
.z-mask {
    z-index: 105;
}
.z-popover {
    z-index: 110;
}
.z-sidebar {
    z-index: 100;
}
.-m-main {
    margin: -1.5rem;
}
.m-0 {
    margin: 0px;
}
.m-auto {
    margin: auto;
}
.m-sm {
    margin: 0.5rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.mx-md {
    margin-left: 1rem;
    margin-right: 1rem;
}
.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}
.my-main {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.my-md {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.my-sm {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.-mb-4 {
    margin-bottom: -1rem;
}
.-mb-\[1px\] {
    margin-bottom: -1px;
}
.-mb-main {
    margin-bottom: -1.5rem;
}
.-ml-\[16px\] {
    margin-left: -16px;
}
.-ml-\[1px\] {
    margin-left: -1px;
}
.-ml-\[2px\] {
    margin-left: -2px;
}
.-ml-\[7px\] {
    margin-left: -7px;
}
.-ml-main {
    margin-left: -1.5rem;
}
.-mt-md {
    margin-top: -1rem;
}
.-mt-navbarHeight {
    margin-top: -4rem;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-1 {
    margin-bottom: 0.25rem;
}
.mb-2xl {
    margin-bottom: 3rem;
}
.mb-4 {
    margin-bottom: 1rem;
}
.mb-8 {
    margin-bottom: 2rem;
}
.mb-\[-5px\] {
    margin-bottom: -5px;
}
.mb-lg {
    margin-bottom: 1.5rem;
}
.mb-main {
    margin-bottom: 1.5rem;
}
.mb-md {
    margin-bottom: 1rem;
}
.mb-sm {
    margin-bottom: 0.5rem;
}
.mb-xl {
    margin-bottom: 2rem;
}
.ml-1 {
    margin-left: 0.25rem;
}
.ml-20 {
    margin-left: 5rem;
}
.ml-auto {
    margin-left: auto;
}
.ml-main {
    margin-left: 1.5rem;
}
.ml-md {
    margin-left: 1rem;
}
.ml-sm {
    margin-left: 0.5rem;
}
.ml-xl {
    margin-left: 2rem;
}
.mr-1 {
    margin-right: 0.25rem;
}
.mr-16 {
    margin-right: 4rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
.mr-\[-3px\] {
    margin-right: -3px;
}
.mr-main {
    margin-right: 1.5rem;
}
.mr-sm {
    margin-right: 0.5rem;
}
.mt-1 {
    margin-top: 0.25rem;
}
.mt-32 {
    margin-top: 8rem;
}
.mt-3xl {
    margin-top: 4rem;
}
.mt-6 {
    margin-top: 1.5rem;
}
.mt-lg {
    margin-top: 1.5rem;
}
.mt-main {
    margin-top: 1.5rem;
}
.mt-md {
    margin-top: 1rem;
}
.mt-navbarHeight {
    margin-top: 4rem;
}
.mt-sm {
    margin-top: 0.5rem;
}
.mt-xl {
    margin-top: 2rem;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.flex {
    display: flex;
}
.grid {
    display: grid;
}
.hidden {
    display: none;
}
.h-6 {
    height: 1.5rem;
}
.h-96 {
    height: 24rem;
}
.h-\[105px\] {
    height: 105px;
}
.h-\[11px\] {
    height: 11px;
}
.h-\[15rem\] {
    height: 15rem;
}
.h-\[164px\] {
    height: 164px;
}
.h-\[16px\] {
    height: 16px;
}
.h-\[16rem\] {
    height: 16rem;
}
.h-\[180px\] {
    height: 180px;
}
.h-\[18rem\] {
    height: 18rem;
}
.h-\[2\.5rem\] {
    height: 2.5rem;
}
.h-\[2\.7rem\] {
    height: 2.7rem;
}
.h-\[200px\] {
    height: 200px;
}
.h-\[20rem\] {
    height: 20rem;
}
.h-\[219px\] {
    height: 219px;
}
.h-\[25px\] {
    height: 25px;
}
.h-\[277px\] {
    height: 277px;
}
.h-\[30px\] {
    height: 30px;
}
.h-\[36px\] {
    height: 36px;
}
.h-\[3px\] {
    height: 3px;
}
.h-\[40px\] {
    height: 40px;
}
.h-\[7px\] {
    height: 7px;
}
.h-full {
    height: 100%;
}
.h-md {
    height: 1rem;
}
.h-navbarHeight {
    height: 4rem;
}
.h-screen {
    height: 100vh;
}
.max-h-\[40px\] {
    max-height: 40px;
}
.max-h-\[50vh\] {
    max-height: 50vh;
}
.max-h-full {
    max-height: 100%;
}
.min-h-\[150px\] {
    min-height: 150px;
}
.min-h-\[8rem\] {
    min-height: 8rem;
}
.min-h-\[calc\(100vh-theme\(space\.navbarHeight\)\)\] {
    min-height: calc(100vh - 4rem);
}
.min-h-screen {
    min-height: 100vh;
}
.w-1\/2 {
    width: 50%;
}
.w-24 {
    width: 6rem;
}
.w-28 {
    width: 7rem;
}
.w-4 {
    width: 1rem;
}
.w-6 {
    width: 1.5rem;
}
.w-\[10px\] {
    width: 10px;
}
.w-\[11px\] {
    width: 11px;
}
.w-\[15rem\] {
    width: 15rem;
}
.w-\[16x\] {
    width: 16x;
}
.w-\[25px\] {
    width: 25px;
}
.w-\[300px\] {
    width: 300px;
}
.w-\[30px\] {
    width: 30px;
}
.w-\[40px\] {
    width: 40px;
}
.w-\[70\%\] {
    width: 70%;
}
.w-\[7px\] {
    width: 7px;
}
.w-\[92\%\] {
    width: 92%;
}
.w-\[calc\(100\%\+\(theme\(space\.main\)\*2\)\)\] {
    width: calc(100% + (1.5rem * 2));
}
.w-\[calc\(100\%\+2px\)\] {
    width: calc(100% + 2px);
}
.w-calc-100-2 {
    width: calc(100% - 2rem);
}
.w-fit {
    width: -moz-fit-content;
    width: fit-content;
}
.w-full {
    width: 100%;
}
.w-md {
    width: 1rem;
}
.w-sidebarOpenWidth {
    width: 22rem;
}
.min-w-\[100px\] {
    min-width: 100px;
}
.min-w-\[180px\] {
    min-width: 180px;
}
.min-w-\[190px\] {
    min-width: 190px;
}
.min-w-\[40px\] {
    min-width: 40px;
}
.min-w-\[48px\] {
    min-width: 48px;
}
.min-w-\[60px\] {
    min-width: 60px;
}
.min-w-\[65px\] {
    min-width: 65px;
}
.min-w-max {
    min-width: -moz-max-content;
    min-width: max-content;
}
.max-w-7xl {
    max-width: 80rem;
}
.max-w-\[1000px\] {
    max-width: 1000px;
}
.max-w-\[19rem\] {
    max-width: 19rem;
}
.max-w-\[400px\] {
    max-width: 400px;
}
.max-w-\[500px\] {
    max-width: 500px;
}
.max-w-\[600px\] {
    max-width: 600px;
}
.max-w-\[640px\] {
    max-width: 640px;
}
.max-w-\[calc\(100\%-3rem\)\] {
    max-width: calc(100% - 3rem);
}
.max-w-\[calc\(100\%-4\.5rem\)\] {
    max-width: calc(100% - 4.5rem);
}
.max-w-full {
    max-width: 100%;
}
.max-w-lg {
    max-width: 32rem;
}
.flex-1 {
    flex: 1 1 0%;
}
.flex-\[1\.5\] {
    flex: 1.5;
}
.flex-shrink-0 {
    flex-shrink: 0;
}
.shrink {
    flex-shrink: 1;
}
.shrink-0 {
    flex-shrink: 0;
}
.grow {
    flex-grow: 1;
}
.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-2\/4 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.cursor-pointer {
    cursor: pointer;
}
.touch-none {
    touch-action: none;
}
.resize {
    resize: both;
}
.list-decimal {
    list-style-type: decimal;
}
.list-disc {
    list-style-type: disc;
}
.list-none {
    list-style-type: none;
}
.grid-cols-\[1\.3fr_1fr\] {
    grid-template-columns: 1.3fr 1fr;
}
.flex-col {
    flex-direction: column;
}
.place-content-center {
    place-content: center;
}
.place-items-center {
    place-items: center;
}
.items-start {
    align-items: flex-start;
}
.items-center {
    align-items: center;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.gap-1 {
    gap: 0.25rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-2xl {
    gap: 3rem;
}
.gap-3 {
    gap: 0.75rem;
}
.gap-\[10px\] {
    gap: 10px;
}
.gap-\[5px\] {
    gap: 5px;
}
.gap-lg {
    gap: 1.5rem;
}
.gap-main {
    gap: 1.5rem;
}
.gap-md {
    gap: 1rem;
}
.gap-sm {
    gap: 0.5rem;
}
.gap-x-main {
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
}
.gap-x-md {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
}
.gap-y-1 {
    row-gap: 0.25rem;
}
.gap-y-md {
    row-gap: 1rem;
}
.self-start {
    align-self: flex-start;
}
.self-end {
    align-self: flex-end;
}
.self-center {
    align-self: center;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-ellipsis {
    text-overflow: ellipsis;
}
.whitespace-normal {
    white-space: normal;
}
.whitespace-nowrap {
    white-space: nowrap;
}
.whitespace-pre-line {
    white-space: pre-line;
}
.break-all {
    word-break: break-all;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-\[4px\] {
    border-radius: 4px;
}
.rounded-\[5px\] {
    border-radius: 5px;
}
.rounded-default {
    border-radius: 6px;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.rounded-md {
    border-radius: 0.375rem;
}
.rounded-none {
    border-radius: 0px;
}
.rounded-b-default {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.rounded-t-default {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.rounded-bl-default {
    border-bottom-left-radius: 6px;
}
.rounded-br-default {
    border-bottom-right-radius: 6px;
}
.rounded-tl-default {
    border-top-left-radius: 6px;
}
.rounded-tr-default {
    border-top-right-radius: 6px;
}
.border {
    border-width: 1px;
}
.border-0 {
    border-width: 0px;
}
.border-2 {
    border-width: 2px;
}
.border-x-0 {
    border-left-width: 0px;
    border-right-width: 0px;
}
.border-b {
    border-bottom-width: 1px;
}
.border-b-0 {
    border-bottom-width: 0px;
}
.border-t-0 {
    border-top-width: 0px;
}
.border-solid {
    border-style: solid;
}
.border-dashed {
    border-style: dashed;
}
.border-dataGridBorder {
    border-color: rgba(224, 224, 224, 1);
}
.border-error {
    --tw-border-opacity: 1;
    border-color: rgb(211 47 47 / var(--tw-border-opacity));
}
.border-grayDivider {
    --tw-border-opacity: 1;
    border-color: rgb(217 217 217 / var(--tw-border-opacity));
}
.border-lightBlack {
    border-color: rgba(0,0,0,0.5);
}
.border-primary {
    --tw-border-opacity: 1;
    border-color: rgb(113 42 217 / var(--tw-border-opacity));
}
.bg-\[\#212121\] {
    --tw-bg-opacity: 1;
    background-color: rgb(33 33 33 / var(--tw-bg-opacity));
}
.bg-\[\#2F1339\] {
    --tw-bg-opacity: 1;
    background-color: rgb(47 19 57 / var(--tw-bg-opacity));
}
.bg-\[\#3F088F\] {
    --tw-bg-opacity: 1;
    background-color: rgb(63 8 143 / var(--tw-bg-opacity));
}
.bg-\[\#5111AD\] {
    --tw-bg-opacity: 1;
    background-color: rgb(81 17 173 / var(--tw-bg-opacity));
}
.bg-\[\#EAEAF0\] {
    --tw-bg-opacity: 1;
    background-color: rgb(234 234 240 / var(--tw-bg-opacity));
}
.bg-\[\#FBFBFB\] {
    --tw-bg-opacity: 1;
    background-color: rgb(251 251 251 / var(--tw-bg-opacity));
}
.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-black\/\[0\.1\] {
    background-color: rgb(0 0 0 / 0.1);
}
.bg-errorBg {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.bg-infoBg {
    --tw-bg-opacity: 1;
    background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}
.bg-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(113 42 217 / var(--tw-bg-opacity));
}
.bg-primary\/90 {
    background-color: rgb(113 42 217 / 0.9);
}
.bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}
.bg-successBg {
    --tw-bg-opacity: 1;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}
.bg-transparent {
    background-color: transparent;
}
.bg-warningBg {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-white\/60 {
    background-color: rgb(255 255 255 / 0.6);
}
.bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}
.bg-opacity-20 {
    --tw-bg-opacity: 0.2;
}
.bg-\[url\(\'\/assets\/clouds\.png\'\)\] {
    background-image: url('/assets/clouds.png');
}
.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.from-slate-100 {
    --tw-gradient-from: #f1f5f9;
    --tw-gradient-to: rgb(241 245 249 / 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-slate-300 {
    --tw-gradient-to: #cbd5e1;
}
.bg-right {
    background-position: right;
}
.bg-repeat-y {
    background-repeat: repeat-y;
}
.object-contain {
    -o-object-fit: contain;
       object-fit: contain;
}
.object-cover {
    -o-object-fit: cover;
       object-fit: cover;
}
.p-0 {
    padding: 0px;
}
.p-2 {
    padding: 0.5rem;
}
.p-4 {
    padding: 1rem;
}
.p-\[10px\] {
    padding: 10px;
}
.p-main {
    padding: 1.5rem;
}
.p-md {
    padding: 1rem;
}
.p-sm {
    padding: 0.5rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-\[0\.3rem\] {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}
.px-main {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-md {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-sm {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-\[5rem\] {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.py-lg {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-main {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-sm {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.pb-main {
    padding-bottom: 1.5rem;
}
.pb-md {
    padding-bottom: 1rem;
}
.pb-sm {
    padding-bottom: 0.5rem;
}
.pl-lg {
    padding-left: 1.5rem;
}
.pl-md {
    padding-left: 1rem;
}
.pl-sm {
    padding-left: 0.5rem;
}
.pr-main {
    padding-right: 1.5rem;
}
.pr-sm {
    padding-right: 0.5rem;
}
.pt-0 {
    padding-top: 0px;
}
.pt-lg {
    padding-top: 1.5rem;
}
.pt-main {
    padding-top: 1.5rem;
}
.pt-md {
    padding-top: 1rem;
}
.pt-sm {
    padding-top: 0.5rem;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-start {
    text-align: start;
}
.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.text-\[1\.7rem\] {
    font-size: 1.7rem;
}
.text-\[10px\] {
    font-size: 10px;
}
.text-\[11px\] {
    font-size: 11px;
}
.text-\[15px\] {
    font-size: 15px;
}
.text-\[24px\] {
    font-size: 24px;
}
.text-\[3rem\] {
    font-size: 3rem;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.font-bold {
    font-weight: 700;
}
.font-medium {
    font-weight: 500;
}
.font-semibold {
    font-weight: 600;
}
.tracking-wider {
    letter-spacing: 0.05em;
}
.text-\[\#00000080\] {
    color: #00000080;
}
.text-\[\#000000b3\] {
    color: #000000b3;
}
.text-\[\#6b7280\] {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-\[\#C5C5C5\] {
    --tw-text-opacity: 1;
    color: rgb(197 197 197 / var(--tw-text-opacity));
}
.text-\[\#EAEAF0\] {
    --tw-text-opacity: 1;
    color: rgb(234 234 240 / var(--tw-text-opacity));
}
.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-black\/50 {
    color: rgb(0 0 0 / 0.5);
}
.text-bronze {
    --tw-text-opacity: 1;
    color: rgb(197 127 67 / var(--tw-text-opacity));
}
.text-error {
    --tw-text-opacity: 1;
    color: rgb(211 47 47 / var(--tw-text-opacity));
}
.text-gold {
    --tw-text-opacity: 1;
    color: rgb(215 186 100 / var(--tw-text-opacity));
}
.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
}
.text-grayDivider {
    --tw-text-opacity: 1;
    color: rgb(217 217 217 / var(--tw-text-opacity));
}
.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity));
}
.text-info {
    --tw-text-opacity: 1;
    color: rgb(3 105 161 / var(--tw-text-opacity));
}
.text-inherit {
    color: inherit;
}
.text-lightBlack {
    color: rgba(0,0,0,0.5);
}
.text-primary {
    --tw-text-opacity: 1;
    color: rgb(113 42 217 / var(--tw-text-opacity));
}
.text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-secondary {
    --tw-text-opacity: 1;
    color: rgb(90 123 238 / var(--tw-text-opacity));
}
.text-silver {
    --tw-text-opacity: 1;
    color: rgb(148 148 148 / var(--tw-text-opacity));
}
.text-success {
    --tw-text-opacity: 1;
    color: rgb(6 95 70 / var(--tw-text-opacity));
}
.text-warning {
    --tw-text-opacity: 1;
    color: rgb(161 98 7 / var(--tw-text-opacity));
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgb(202 138 4 / var(--tw-text-opacity));
}
.underline {
    text-decoration-line: underline;
}
.line-through {
    text-decoration-line: line-through;
}
.no-underline {
    text-decoration-line: none;
}
.opacity-0 {
    opacity: 0;
}
.opacity-50 {
    opacity: 0.5;
}
.opacity-80 {
    opacity: 0.8;
}
.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-default {
    --tw-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.06);
    --tw-shadow-colored: 0px 1px 3px var(--tw-shadow-color), 0px 1px 2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-small {
    --tw-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    --tw-shadow-colored: 0px 0px 4px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
    outline-style: solid;
}
.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur {
    --tw-backdrop-blur: blur(8px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-\[margin-left\] {
    transition-property: margin-left;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.divider {
    border-width: 0px;
    border-bottom-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(217 217 217 / var(--tw-border-opacity));
}
.break-word {
    word-break: break-word;
  }

/* Override of 3rd party library styles { */

.recharts-tooltip-wrapper:focus-visible {
  outline: none;
}

.MuiDataGrid-cellContent {
  text-overflow: clip !important;
}

.MuiDataGrid-columnHeaderTitle {
  text-overflow: clip !important;
}

/* } Override of 3rd party library styles */

:root {
  --border-radius: 12px;
  --font-inter: 'Pacifico', cursive;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color: #000000;
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
  html {
    color-scheme: dark;
  }
}

.hover\:cursor-grab:hover {
    cursor: grab;
}

.hover\:cursor-pointer:hover {
    cursor: pointer;
}

.hover\:bg-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(113 42 217 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hover\:text-primary:hover {
    --tw-text-opacity: 1;
    color: rgb(113 42 217 / var(--tw-text-opacity));
}

.hover\:text-sky-500:hover {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity));
}

@media not all and (min-width: 1024px) {

    .max-lg\:w-full {
        width: 100%;
    }
}

@media (min-width: 640px) {

    .sm\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .sm\:mb-0 {
        margin-bottom: 0px;
    }

    .sm\:mt-0 {
        margin-top: 0px;
    }

    .sm\:block {
        display: block;
    }

    .sm\:flex {
        display: flex;
    }

    .sm\:w-96 {
        width: 24rem;
    }

    .sm\:w-\[18rem\] {
        width: 18rem;
    }

    .sm\:w-\[640px\] {
        width: 640px;
    }

    .sm\:w-auto {
        width: auto;
    }

    .sm\:max-w-none {
        max-width: none;
    }

    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .sm\:grid-cols-\[1fr_100px\] {
        grid-template-columns: 1fr 100px;
    }

    .sm\:grid-cols-\[1fr_1fr_1fr_50px\] {
        grid-template-columns: 1fr 1fr 1fr 50px;
    }

    .sm\:grid-cols-\[1fr_200px_200px\] {
        grid-template-columns: 1fr 200px 200px;
    }

    .sm\:items-center {
        align-items: center;
    }

    .sm\:justify-start {
        justify-content: flex-start;
    }

    .sm\:justify-end {
        justify-content: flex-end;
    }

    .sm\:gap-main {
        gap: 1.5rem;
    }

    .sm\:justify-self-end {
        justify-self: end;
    }

    .sm\:px-20 {
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .sm\:pl-1 {
        padding-left: 0.25rem;
    }

    .sm\:pt-0 {
        padding-top: 0px;
    }
}

@media (min-width: 768px) {

    .md\:left-1 {
        left: 0.25rem;
    }

    .md\:top-5 {
        top: 1.25rem;
    }

    .md\:mb-auto {
        margin-bottom: auto;
    }

    .md\:ml-auto {
        margin-left: auto;
    }

    .md\:mr-md {
        margin-right: 1rem;
    }

    .md\:flex {
        display: flex;
    }

    .md\:h-\[40px\] {
        height: 40px;
    }

    .md\:h-\[438px\] {
        height: 438px;
    }

    .md\:w-1\/3 {
        width: 33.333333%;
    }

    .md\:w-32 {
        width: 8rem;
    }

    .md\:w-\[100px\] {
        width: 100px;
    }

    .md\:w-\[15rem\] {
        width: 15rem;
    }

    .md\:w-\[330px\] {
        width: 330px;
    }

    .md\:w-\[359px\] {
        width: 359px;
    }

    .md\:w-auto {
        width: auto;
    }

    .md\:min-w-\[12rem\] {
        min-width: 12rem;
    }

    .md\:min-w-\[350px\] {
        min-width: 350px;
    }

    .md\:min-w-\[95px\] {
        min-width: 95px;
    }

    .md\:max-w-\[16rem\] {
        max-width: 16rem;
    }

    .md\:max-w-\[350px\] {
        max-width: 350px;
    }

    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .md\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .md\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .md\:grid-cols-\[1fr__250px\] {
        grid-template-columns: 1fr  250px;
    }

    .md\:grid-cols-\[repeat\(auto-fit\2c minmax\(250px\2c 1fr\)\)\] {
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
    }

    .md\:grid-cols-\[repeat\(auto-fit\2c minmax\(400px\2c 1fr\)\)\] {
        grid-template-columns: repeat(auto-fit,minmax(400px,1fr));
    }

    .md\:flex-row {
        flex-direction: row;
    }

    .md\:justify-end {
        justify-content: flex-end;
    }

    .md\:justify-between {
        justify-content: space-between;
    }

    .md\:gap-\[5rem\] {
        gap: 5rem;
    }

    .md\:gap-main {
        gap: 1.5rem;
    }

    .md\:justify-self-end {
        justify-self: end;
    }

    .md\:p-main {
        padding: 1.5rem;
    }

    .md\:text-center {
        text-align: center;
    }
}

@media (min-width: 1024px) {

    .lg\:col-start-3 {
        grid-column-start: 3;
    }

    .lg\:row-start-1 {
        grid-row-start: 1;
    }

    .lg\:ml-sidebarOpenWidth {
        margin-left: 22rem;
    }

    .lg\:mt-0 {
        margin-top: 0px;
    }

    .lg\:grid {
        display: grid;
    }

    .lg\:w-10\/12 {
        width: 83.333333%;
    }

    .lg\:w-\[26rem\] {
        width: 26rem;
    }

    .lg\:grow-\[0\.3\] {
        flex-grow: 0.3;
    }

    .lg\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .lg\:grid-cols-\[300px_1fr\] {
        grid-template-columns: 300px 1fr;
    }

    .lg\:flex-row {
        flex-direction: row;
    }

    .lg\:justify-end {
        justify-content: flex-end;
    }

    .lg\:self-start {
        align-self: flex-start;
    }

    .lg\:p-28 {
        padding: 7rem;
    }

    .lg\:pr-main {
        padding-right: 1.5rem;
    }

  .lg\:divider-r {
        border-width: 0px;
        border-right-width: 1px;
        border-style: solid;
        --tw-border-opacity: 1;
        border-color: rgb(217 217 217 / var(--tw-border-opacity));
    }
}

@media (min-width: 1280px) {

    .xl\:flex-row {
        flex-direction: row;
    }
}

.\[\&\.Mui-active\]\:bg-primary.Mui-active {
    --tw-bg-opacity: 1;
    background-color: rgb(113 42 217 / var(--tw-bg-opacity));
}

.\[\&\.Mui-completed\]\:bg-primary.Mui-completed {
    --tw-bg-opacity: 1;
    background-color: rgb(113 42 217 / var(--tw-bg-opacity));
}

.\[\&_\.MuiButton-startIcon\]\:mr-1 .MuiButton-startIcon {
    margin-right: 0.25rem;
}

.\[\&_\.MuiGrid-root\]\:h-full .MuiGrid-root {
    height: 100%;
}

.\[\&_\.MuiInputBase-input\]\:box-border .MuiInputBase-input {
    box-sizing: border-box;
}

.\[\&_\.MuiInputBase-input\]\:p-md .MuiInputBase-input {
    padding: 1rem;
}

.\[\&_\.MuiInputBase-root_\.MuiInputBase-input\]\:h-14 .MuiInputBase-root .MuiInputBase-input {
    height: 3.5rem;
}

.\[\&_\.MuiStepConnector-line\]\:hidden .MuiStepConnector-line {
    display: none;
}

.\[\&_\.MuiTablePagination-toolbar\>div\]\:m-0 .MuiTablePagination-toolbar>div {
    margin: 0px;
}

@media (min-width: 640px) {

    .\[\&_\.MuiTablePagination-toolbar\>div\]\:sm\:mx-main .MuiTablePagination-toolbar>div {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}

.\[\&_\.MuiTablePagination-toolbar\]\:flex-col .MuiTablePagination-toolbar {
    flex-direction: column;
}

.\[\&_\.MuiTablePagination-toolbar\]\:py-main .MuiTablePagination-toolbar {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

@media (min-width: 640px) {

    .\[\&_\.MuiTablePagination-toolbar\]\:sm\:flex-row .MuiTablePagination-toolbar {
        flex-direction: row;
    }

    .\[\&_\.MuiTablePagination-toolbar\]\:sm\:py-0 .MuiTablePagination-toolbar {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.\[\&_text\]\:hidden text {
    display: none;
}

/* latin-ext */
@font-face {
  font-family: '__Poppins_6ba5be';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/fe0777f1195381cb.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6ba5be';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url(/_next/static/media/eafabf029ad39a43.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_6ba5be';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/f10b8e9d91f3edcb.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6ba5be';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url(/_next/static/media/8888a3826f4a3af4.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_6ba5be';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url(/_next/static/media/c3bc380753a8436c.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6ba5be';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url(/_next/static/media/0484562807a97172.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_6ba5be';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(/_next/static/media/a1386beebedccca4.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6ba5be';
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url(/_next/static/media/b957ea75a84b6ea7.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_6ba5be';src: local("Arial");ascent-override: 92.83%;descent-override: 30.94%;line-gap-override: 8.84%;size-adjust: 113.11%
}.__className_6ba5be {font-family: '__Poppins_6ba5be', '__Poppins_Fallback_6ba5be';font-style: normal
}

